<script setup>
import {onMounted, reactive, ref, watch} from 'vue'
import {onBeforeRouteLeave} from 'vue-router'
import {Feedback, Progress, State, can, clone, getRoute, navigate} from '@/paks/vu-app'
import Query from '@/paks/js-query'
import Planner from '@/common/planner'
import CloudEdit from './CloudEdit.vue'
import {Cloud} from '@/models'

const Fields = [
    {name: 'edit', icon: (v, rec) => (rec.eval ? '' : '$edit'), width: '5%'},
    {name: 'enable', title: 'Enabled', icon: 'check', align: 'center', width: '5%'},
    {name: 'name'},
    {name: 'region', title: 'Region'},
    {
        name: 'type',
        title: 'Deployment',
        format: (v, cloud) =>
            v == 'dedicated'
                ? `Dedicated AWS Account ${cloud.awsAccount}`
                : v == 'hosted'
                ? 'Hosted'
                : 'Shared',
    },
    {name: 'details'},
    {name: 'schema', format: (v) => (v && v.data ? 'Customized' : 'Default')},
    {name: 'volume', title: 'Connecting Devices', align: 'right'},
]

const page = reactive({
    canAdd: can('admin') && !State.app.suspended,
    clouds: [],
    cloud: {},
    confirmCloud: null,
    eval: State.config.evalProductToken,
    fields: [],
    showEdit: false,
    select: null,
})

const route = getRoute()

//  Component references
const confirm = ref(null)
const edit = ref(null)
const table = ref(null)

let planner = new Planner(State.config.billing)

watch(
    () => State.app.mock,
    () => {
        if (table.value) {
            table.value.update()
        }
    }
)

onMounted(async () => {
    if (page.canAdd) {
        page.select = {
            actions: {Add: 0, Edit: 1, Delete: 2},
            multi: true,
            property: 'select',
        }
    }
    if (route.params.id) {
        let cloud = page.clouds.find((c) => c.id == route.params.id)
        if (cloud) {
            page.cloud = cloud
            editCloud(cloud)
        }

    } else if (Query('add')) {
        editCloud({})
    }
    page.fields = Fields
    if (!can('admin')) {
        page.fields.splice(0, 1)
    }
    let soon = new Date(Date.now() + 86400 * 7 * 1000)
    for (let cloud of page.clouds) {
        let plan = State.get('Plan', cloud.id)
        if (plan) {
            let price = planner.getPrice(State.auth.account, plan)
            if (!State.app.mock && plan.pending && plan.start < soon && price > 0) {
                page.confirmCloud = `${cloud.name}`
            }
        }
    }
})

onBeforeRouteLeave(() => confirmCloseEdit(true))

async function getData(args) {
    if (args.reload) {
        await State.cache.update()
    }
    let clouds = State.find('Cloud').filter((c) => c.type != 'host')
    if (State.app.mock) {
        clouds = clouds.filter((p) => p.id != State.config.evalCloud)
    }
    let list = []
    for (let cloud of Object.values(clouds)) {
        let details
        if (cloud.error) {
            details = cloud.error
        } else if (!cloud.enable) {
            details = `Cloud disabled.`
        } else if (+cloud.version < State.app.versions.cloud) {
            details = `Cloud version ${cloud.version} needs updating to version ${State.app.versions.cloud}. Edit and re-save.`
        } else if (cloud.connected) {
            details = 'Operational'
        }
        let item = clone(cloud)
        let plan = State.get('Plan', cloud.planId)

        Object.assign(item, {
            id: cloud.id,
            enable: cloud.enable,
            name: cloud.name,
            region: cloud.region,
            awsAccount: cloud.awsAccount,
            volume: plan?.count,
            details,
        })
        list.push(item)
    }
    if (State.config.profile == 'prod' && !(can('support') && !State.app.mock)) {
        list.unshift({
            enable: true,
            name: 'Eval',
            region: 'us-east-1',
            type: 'eval',
            details: 'Operational',
            volume: '-',
            eval: true,
        })
    }
    page.clouds = list
    return page.clouds
}

async function clicked({action, item, items}) {
    if (item.eval) return
    if (action == 'add') {
        editCloud({})
    } else if (action == 'edit') {
        for (let cloud of items) {
            editCloud(cloud)
            break
        }
    } else if (action == 'delete') {
        for (let item of items) {
            await deleteCloud(item)
        }
    } else if (action == 'cell') {
        if (can('admin')) {
            editCloud(item)
        }
    }
}

async function deleteCloud(cloud) {
    if (
        await confirm.value.ask(
            `Do you want to delete the "${cloud.name}".` +
                `This will delete all associated device data.` +
                `It will not impact your data stored in AWS.`
        )
    ) {
        try {
            Progress.start('repeat')
            await Cloud.remove({id: cloud.id})
            Feedback.info('Cloud Data Deleted')
        } catch (err) {
            Feedback.error('Cannot delete cloud')
            throw err
        } finally {
            await State.cache.update()
            Progress.stop()
            //  To update the sidebar
            State.app.setNeed('dash', 'reload')
        }
    }
}

async function editCloud(cloud) {
    page.showEdit = cloud ? true : false
    page.cloud = cloud || {}
    if (!cloud) {
        //  Refresh cloud and plans list
        await State.cache.update()
        //  Workaround
        if (table.value) {
            table.value.update()
        }
    }
}

async function confirmCloseEdit(discard) {
    return discard && (edit.value?.page?.saving || edit.value?.page?.removing) ? false : true
}

function aws() {
    let url = `https://console.aws.amazon.com/cloudformation/home?filteringStatus=active&filteringText=&viewNested=true&hideStacks=false`
    window.open(url, '_blank')
}
</script>

<template>
    <div class="page cloud-list">
        <vu-help url="/doc/ui/clouds/list.html" v-if="!page.showEdit" />

        <vu-sign
            v-if="page.confirmCloud"
            color="error"
            name="cloud-list-sign-0"
            title="Confirm Device Cloud"
            subtitle=""
            :required="true">
            <p>
                Please
                <a @click="navigate('/account/subscription')">Confirm</a>
                your device cloud purchase to activate.
            </p>
            <p>
                <v-btn color="accent" @click="navigate('/account/subscription')">
                    Confirm Cloud
                </v-btn>
            </p>
        </vu-sign>
        <vu-sign
            name="cloud-list-sign-1"
            title="Device Clouds"
            subtitle="Create a device cloud"
            color="accent">
            <p>
                Device clouds stores device data and manage communications between your Ioto based
                devices and the cloud. Device clouds also host your device software updates 
                for all types of device agents.</p>
            <p>
                If evaluating Ioto, you can use the pre-created Eval cloud with the Product ID
                token <b>{{ page.eval }}</b> in your Ioto agent <b>device.json5</b> file for a limited evaluation.
            </p>
            <p>
                When you are ready, you can create one or more device clouds for your exclusive 
                use in a region close to you.  You can have EmbedThis host the device cloud or
                you can create it in an AWS account of your choosing. 
                With your own device cloud, you can configure automated device actions.
            </p>
        </vu-sign>

        <vu-table
            name="cloud-list"
            options="dynamic,filter,refilter,toolbar"
            ref="table"
            sort="name:asc"
            subtitle="This is the list of regional device clouds for devices using the Ioto Agent. Stats update per hour."
            title="Device Clouds"
            width="100%"
            :data="getData"
            :fields="page.fields"
            :select="page.select"
            @click="clicked">
            <template v-slot:more="props">
                <v-btn
                    color="accent"
                    class="mr-2"
                    id="_add"
                    @click="editCloud({})"
                    :disabled="!page.canAdd">
                    Add Cloud
                </v-btn>
            </template>
        </vu-table>

        <vu-panel
            v-model="page.showEdit"
            @close="editCloud"
            :widths="['650px']"
            :confirm="confirmCloseEdit">
            <CloudEdit :id="page.cloud.id" @input="editCloud" ref="edit" />
        </vu-panel>

        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.cloud-list {
    #add {
        color: white !important;
        background: rgb(var(--v-theme-background-lighten-1)) !important;
    }
}
</style>
