/*
    Route setup for apps using Display.json

    WARNING: do not import vu-app
 */
import {Auth} from '@/paks/vu-auth'
import {Feedback} from '@/paks/vu-feedback'
import {State} from '@/paks/vu-state'
import {titlecase} from '@/paks/js-polyfill'
import clone from '@/paks/js-clone'

export default class Routes {

    init(router, components = []) {
        this.router = router
        this.components = components || []
        for (let [key, component] of Object.entries(components)) {
            this.components[key] = component
        }
    }

    async addRoutes(display) {
        this.display = clone(display)
        for (let view of display.views) {
            await this.addView(view)
        }
        document.title = State.app.display.title
    }

    async addView(view, parent = null, role = null) {
        role = view.role || role
        /*
            Check the properties in view.enable: {}
         */
        if (!State.app.testContext(view.enable, true, true)) {
            return
        }
        if (view.panels) {
            await this.definePanels(view)
        }
        /*
            Title defaults to a TitleCase(name)
            Path defaults to the view name
         */
        let title = (view.title = view.title || titlecase(view.name))
        view.path = this.rebase(parent, view.path != null ? view.path : view.name)
        view.redirect = this.rebase(view, view.redirect)

        /*
            view.tabs == false              // No tabs
            view.tabs == [{to,name},...]    // Explicit tabs
            view.tabs == []                 // new tab set
         */
        if (view.tabs === undefined) {
            if (parent?.tabs) {
                parent.tabs.push({
                    path: view.path, 
                    name: view.tab || view.name, 
                    enable: view.enable,
                    role: view.role,
                })
                view.tabs = parent.tabs
            }
        }
        if (typeof view.component == 'string') {
            view.component = await this.getComponent(view.component)
        }
        let views = (view.views || []).concat(view.children || [])
        for (let child of views) {
            await this.addView(child, view, role)
        }
        let route = {
            component: view.component,
            name: view.name,
            path: view.path,
            redirect: view.redirect,
            meta: {role, title, view},
            children: view.children || [],
            beforeEnter: this.enterRoute,
        }
        if (!parent || !parent.children || parent.children.indexOf(view) < 0) {
            this.router.addRoute(route)
        }
    }

    rebase(parent, url) {
        if (!url) return url
        return url[0] == '/' ? url : (parent ? parent.path : '') + '/' + url
    }

    async definePanels(view) {
        for (let panel of view.panels) {
            if (panel.path) {
                panel.path = `${view.path}/${panel.path}`
            }
            panel.fields = panel.fields || []
            if (panel.component) {
                panel.componentRef = await this.getComponent(panel.component)
            }
        }
    }

    enterRoute(to, from, next) {
        //  WARNING: "this" is not equal to this class
        if (State.app.unsaved && State.app.unsaved(to)) {
            next(false)
            return
        }
        let {meta} = to
        let {role} = meta

        if (!Auth.can(role)) {
            Feedback.error('Insufficient Privilege')
            next(false)
            return
        }
        State.app.location.prior = to.path
        State.app.setActivity()

        if (meta?.view?.enable && !State.app.testContext(meta?.view?.enable, true, false)) {
            next('/')
            return
        }
        /*
            Extract params
         */
        for (let [key, value] of Object.entries(to.params)) {
            if (key == 'deviceId' && value == 'select') continue
            if (key == 'id') continue
            State.app.setContext(key, value)
        }
        next()
    }

    async getComponent(name) {
        if (!name) {
            return null
        }
        let component = this.components[name]
        if (!component) {
            //  Check globally registered components
            let components = State.ref.vue._context.components
            if (components[name]) {
                return components[name]
            }
            console.error(`Cannont find component ${name}`)
            return null
        }
        return component
    }

    getPanel(path = window.location.pathname, extra = '') {
        let view = this.getView(path)
        if (!view || !view.panels) {
            return null
        }
        if (extra) {
            path = `${path}/${extra}`
        }
        for (let panel of view.panels) {
            if (path.indexOf(panel.path) == 0) {
                return panel
            }
        }
        return null
    }

    template(s, ...contexts) {
        if (!contexts || !s) return s
        for (let context of contexts) {
            s = s.replace(/:([^/ ]+)/g, (a, b) => {
                if (context[b]) {
                    return context[b]
                } else {
                    return a
                }
            })
        }
        return s
    }
}
