<script setup>
import {onBeforeMount, reactive, ref, watch} from 'vue'
import {
    Feedback,
    Log,
    Progress,
    State,
    can,
    clone,
    delay,
    getRoute,
    money,
    titlecase,
    toTitle,
    unique,
    waitRender,
} from '@/paks/vu-app'
import Dates from '@/paks/js-dates'
import UUID from '@/paks/js-uuid'
import Planner from '@/common/planner'
import {Cloud, Plan, Token} from '@/models'
import Json5 from 'json5'

const Regions = [
    {value: 'ap-east-1', title: 'ap-east-1 (Hong Kong)'},
    {value: 'ap-northeast-1', title: 'ap-northeast-1 (Tokyo)'},
    {value: 'ap-northeast-2', title: 'ap-northeast-2 (Seoul)'},
    {value: 'ap-south-1', title: 'ap-south-1 (Mumbai)'},
    {value: 'ap-southeast-1', title: 'ap-southeast-1 (Singapore)'},
    {value: 'ap-southeast-2', title: 'ap-southeast-2 (Sydney)'},
    {value: 'ca-central-1', title: 'ca-central-1 (Canada)'},
    {value: 'eu-central-1', title: 'eu-central-1 (Frankfurt)'},
    {value: 'eu-north-1', title: 'eu-north-1 (Stockholm)'},
    {value: 'eu-west-1', title: 'eu-west-1 (Ireland)'},
    {value: 'eu-west-2', title: 'eu-west-2 (London)'},
    {value: 'eu-west-3', title: 'eu-west-3 (Paris)'},
    {value: 'me-central-1', title: 'me-central-1 (UAE)'},
    {value: 'me-south-1', title: 'me-south-1 (Bahrain)'},
    {value: 'sa-east-1', title: 'sa-east-1 (Sao Paulo)'},
    {value: 'us-east-1', title: 'us-east-1 (N. Virginia)'},
    {value: 'us-east-2', title: 'us-east-2 (Ohio)'},
    {value: 'us-west-1', title: 'us-west-1 (N. California)'},
    {value: 'us-west-2', title: 'us-west-2 (Oregon)'},

    //  This supports IoT Core
    // {value: 'ca-central-1', title: 'ca-central-1 (Canada Central)'},

    //  These do not support IoT Core
    // {value: 'af-south-1', text: 'af-south-1 (Cape Town)'},
    // {value: 'ap-northeast-3', text: 'ap-northeast-3 (Osaka)'},
    // {value: 'ap-south-2', text: 'ap-south-2 (Hyderabad)'},
    // {value: 'ap-southeast-3', text: 'ap-southeast-3 (Jakarta)'},
    // {value: 'ap-southeast-4', text: 'ap-southeast-4 (Melbourne)'},
    // {value: 'eu-central-2', text: 'eu-central-2 (Zurich)'},
    // {value: 'eu-south-1', text: 'eu-south-1 (Milan)'},
]

const MaxJson = 1024 * 128

const CloudFormationTimeout = 8 * 60

const props = defineProps({
    id: String,
    // hosted: Boolean,
})

const emit = defineEmits(['input'])

const page = reactive({
    advancedPanel: null,
    ask: false,
    builderToken: {},
    clear: null,
    cloud: {},
    evalCloud: State.config.evalCloud,
    files: {
        schema: null,
    },
    fresh: false,
    hosted: false,
    loading: false,
    mock: State.app.mock,
    monthly: true,
    partial: '',
    plan: {current: {count: 0, units: 1}, count: 0, units: 1},
    prior: {},
    price: 0,
    range: null,
    regions: Regions,
    removing: false,
    rules: [],
    saving: false,
    start: null,
    template: null,
    timeout: 180,
    token: {},
    total: 0,
    monthlyPrice: 0,
    upfrontPrice: 0,
    updating: false,
    upload: {
        schema: null,
    },
    yearlyPrice: 0,
})

const route = getRoute()
const confirm = ref(null)
const form = ref(null)
const planner = new Planner(State.config.billing)

/*
watch(
    () => page.clear,
    () => clearSchema()
) */

defineExpose({page})

onBeforeMount(async () => {
    let id = props.id || route.params.id
    let cloud
    if (id) {
        cloud = Object.assign(page.cloud, State.get('Cloud', id))
        cloud.plan = State.get('Plan', cloud.planId)
        page.advancedPanel = true
        page.updating = true
    } else {
        page.fresh = true
        cloud = Object.assign(page.cloud, {
            enable: true,
            iotPolicy: 'IotoThingPolicy',
            plan: {
                current: {units: 1},
                period: 'month',
                type: 'Hosted',
                units: 1,
            },
            region: State.app.region || (can('support') ? 'ap-southeast-2' : 'us-east-1'),
            sync: true,
            type: 'hosted',
        })
        page.advancedPanel = true
    }
    page.hosted = cloud.type == 'hosted' ? true : false
    let plan = (page.plan = cloud.plan = planner.initPlan(cloud.plan))
    page.prior = clone(plan)
    page.monthly = plan.period == 'month' ? true : false
    plan.units = plan.units || 0
    plan.count = plan.count || 0

    if (plan.period == 'month') {
        plan.units = plan.count
    } else {
        plan.units = Math.max(plan.count, plan.units, plan.current.units || 0)
    }

    updateSummary()

    await changeType(cloud.type == 'hosted' ? true : false)
    page.token = await Token.get({type: 'CloudAPI', owner: `cloud:${cloud.id}`, enable: true})

    watch(() => page.plan.align, changeAlign)
    watch(() => page.monthly, changePeriod)
    watch(() => page.plan.units, updateSummary)
})

function changeAlign() {
    planner.setPlanRange(page.plan, {reset: true})
    updateSummary()
}

function changePeriod() {
    let {plan, prior} = page
    if (page.monthly) {
        plan.period = 'month'
        plan.advance = false
        plan.units = plan.count
    } else {
        plan.period = 'year'
        plan.advance = true
        plan.units = Math.max(plan.count, plan.units, plan.current.units || 0)
    }
    plan.pending = true
    if (plan.period != prior.period) {
        if (plan.period == 'month') {
            plan.units = plan.count
        } else {
            plan.units = Math.min(1, prior.units * 12)
        }
    }
    planner.setPlanRange(plan, {reset: true})
    updateSummary()
}

async function runTemplate() {
    let cloud = page.cloud
    page.builderToken = await Token.get({
        type: 'CloudToBuilder',
        owner: `cloud:${cloud.id}`,
        enable: true,
    })
    await cleanupDialog()

    let profile = State.config.profile == 'prod' ? 'prod' : 'qa'
    let uri = `https://console.aws.amazon.com/cloudformation/home?region=${cloud.region}#/stacks/create/review?`
    let args = []
    args.push(`templateURL=${State.config.admin.formation}`)
    args.push(`param_AccountID=${State.auth.accountId}`)
    args.push(`param_BuilderAccessToken=${page.builderToken.id}`)
    args.push(`param_Cloud=${cloud.id}`)
    args.push(`param_ExternalID=${cloud.external}`)
    args.push(`param_EmbedthisAwsAccount=${State.config.admin.account}`)
    args.push(`param_Profile=${profile}`)
    args.push(`stackName=Ioto`)
    page.template = encodeURI(uri + args.join('&'))
    page.ask = true

    startProgress(`Running CloudFormation`, CloudFormationTimeout)

    let start = Date.now()
    while (Date.now() < start + CloudFormationTimeout * 1000) {
        //  TODO remove try/catch
        try {
            if (!page.cloud) {
                throw new Error('Cloud create cancelled')
            }
            let cloud = await Cloud.waitForTemplate({id: page.cloud.id})
            if (!cloud) {
                throw new Error('Cloud create cancelled')
            }
            if (cloud.error || page.cloud.error) {
                throw new Error(cloud.error || page.cloud.error)
            }
            if (cloud.connected) {
                page.cloud.connected = cloud.connected
            }
            if (
                cloud.status &&
                cloud.status.indexOf('PENDING') < 0 &&
                cloud.status.indexOf('PROGRESS') < 0
            ) {
                return
            }
            await delay(5000)
        } catch (err) {
            if (err.message == 'Cloud create cancelled' || err.message == 'Cancelled operation') {
                throw err
            }
            Log.error(`CloudFormation template error ${err.message}`)
            // continue
        }
    }
    throw new Error(
        'Timeout waiting for Cloud Formation Template.' +
            'See AWS Console CloudFormation Ioto stack for error details. ' +
            'Contact support via chat if you need assistance.'
    )
}

async function startTemplate() {
    page.ask = false
    page.start = new Date()
    window.open(page.template, '_blank')
}

async function cancelTemplate() {
    let cloud = page.cloud
    if (page.fresh && !cloud.provisioned && !cloud.connected) {
        page.cloud = null
        await Cloud.remove({id: cloud.id})
        await State.cache.update()
    }
    Progress.stop()
    await cleanupDialog()
    emit('input')
}

async function cleanupDialog() {
    let elements = document.getElementsByClassName('v-overlay theme--dark')
    if (elements) {
        for (let overlay of elements) {
            overlay.parentNode.removeChild(overlay)
        }
    }
    page.ask = null
    await waitRender()
}

async function preSave(validate) {
    let {cloud} = page
    if (cloud.error == 'Template aborted') {
        cloud.error = null
    }
    if (!cloud.name) {
        await validate.fieldError(form.value, 'name', 'Missing cloud name')
        return false
    }
    if (!cloud.id && State.cache.clouds.find((c) => c.name == cloud.name)) {
        await validate.fieldError(form.value, 'name', 'An existing cloud has the same name')
        return false
    }
    if (!cloud.region) {
        await validate.fieldError(form.value, 'region', 'Must define cloud region')
        return false
    }
    startProgress(`${cloud.id ? 'Updating' : 'Creating'} Cloud "${cloud.name}"`, 180)
    return true
}

async function save() {
    let {cloud, files, upload} = page
    page.saving = true

    if (page.clear && cloud.schema) {
        cloud.schema = null
        upload.schema = null
    }
    let params = {
        id: cloud.id,
        attachments: page.attachments,
        deviceRole: cloud.deviceRole,
        enable: cloud.enable,
        error: null,
        iotPolicy: cloud.iotPolicy,
        name: cloud.name.trim(),
        period: page.monthly ? 'month' : 'year',
        region: cloud.region.trim(),
        // schema -- preserve existing schema
        shadows: cloud.shadows,
        sync: cloud.sync,
        type: page.hosted ? 'hosted' : 'dedicated',
        units: page.plan.units,
    }
    if (upload.schema) {
        params.schema = await getAsset(files.schema, MaxJson, 'json')
    } else if (!cloud.schema) {
        params.schema = null
    }
    if (!page.hosted && !cloud.connected) {
        params.external = UUID()
    }
    if (cloud.id) {
        cloud = page.cloud = await Cloud.update(params)
    } else {
        cloud = page.cloud = await Cloud.create(params, {feedback: false})
    }
    //  Restore because the service never returns this value and is needed by runTemplate()
    cloud.external = params.external
    //  Cache the plan
    let plan = await Plan.get({id: cloud.planId})
    //  Just for debug
    return plan
}

async function postSave(error, val) {
    let cloud = page.cloud
    if (!cloud || error) return

    try {
        page.saving = true
        if (cloud.enable && cloud.type == 'dedicated' && !cloud.connected) {
            await runTemplate()
        }
        await provision(cloud)
        State.app.region = cloud.region

        if (confirm.value) {
            await confirm.value.ask(`Now create an App to manage your devices. `, {
                title: 'Please Note',
                disagree: false,
            })
        }
        emit('input')
    } catch (err) {
        await Cloud.update({id: cloud.id, error: err.message})
        if (err.message != 'Cloud create cancelled') {
            //  Continue without emit
            val.error(err.message)
            Log.error(`Cloud provision error ${err.message}`)
            Feedback.error(err.message)
        }
    } finally {
        Progress.stop()
        page.saving = false
        //  To update the sidebar
        await State.cache.update()
        State.app.setNeed('dash', 'reload')
    }
}

async function provision(cloud) {
    startProgress('Provisioning Cloud Resources', cloud.type == 'dedicated' ? 300 : 100)
    cloud = await Cloud.provision({id: cloud.id})
    let prior = cloud.provisioned || new Date()

    let deadline = Date.now() + 3 * 60 * 1000
    while (Date.now() < deadline) {
        let error = cloud.error || page.cloud.error
        if (error) {
            throw new Error(error)
        }
        if (cloud.provisioned > prior) {
            return
        }
        await delay(2000)
        cloud = await Cloud.get({id: cloud.id}, {refresh: true, throw: false})
    }
    throw new Error('Timeout waiting for Provisioning')
}

async function removeCloud() {
    let {cloud} = page
    let confirmPrompt = `Delete ${cloud.name}`

    if (
        confirm.value &&
        !(await confirm.value.ask(
            `
            <h2 class="mb-3">WARNING: Deleting a cloud is permanent and cannot be reversed.</h2>
            <p>Deleting a device cloud will disconnect all devices from your device cloud and stop any devices being managed by Ioto.</p>
            <label>To confirm the deletion of this cloud, type <i>${confirmPrompt}</i> in the text box.</label>
            `,
            {
                confirmPrompt,
                width: 840,
            }
        ))
    ) {
        return
    }
    try {
        page.removing = true
        startProgress(`Deleting Cloud "${cloud.name}"`, 300)
        //  Remove is async and non-blocking. Does not remove immediately.
        await Cloud.remove({id: cloud.id})

        let deadline = Date.now() + 5 * 60 * 1000
        while (Date.now() < deadline) {
            cloud = await Cloud.get({id: cloud.id}, {throw: false, refresh: true})
            if (!cloud) {
                await State.cache.update()
                break
            }
            if (cloud.error || page.cloud.error) {
                throw new Error(cloud.error)
            }
            await delay(5000)
            await State.cache.update()
        }

        await confirm.value.ask(
            `If you want to re-create the cloud, please wait ten minutes to allow previous resources to be fully removed.`,
            {title: 'Please Note', disagree: false}
        )
        Feedback.info('Cloud Data Deleted')
        emit('input')
    } catch (err) {
        Log.info(`Remove error ${err.message}`)
        Feedback.error('Cannot delete cloud')
    } finally {
        Progress.stop()
        //  To update the sidebar
        State.app.setNeed('dash', 'reload')
        page.removing = false
    }
}

async function changeType() {
    page.cloud.type = page.hosted ? 'hosted' : 'dedicated'
    page.plan.type = toTitle(page.cloud.type)
    if (page.hosted) {
        let clouds = await Cloud.getHosts({open: true})
        page.regions = unique(
            clouds.map((c) => {
                let city = Regions.find((r) => r.value == c.region).title.split(/\(/)[1]
                return {title: `${c.region} (${city}`, value: c.region}
            })
        ).sort()
        if (page.regions.length == 1) {
            page.cloud.region = page.regions[0].value
        }
    } else {
        page.regions = Regions
    }
    updateSummary()
}

function updateSummary() {
    let account = State.auth.account
    let plan = page.plan
    getRange()
    if (plan.units != '') {
        plan.units = parseInt(plan.units) || 1
    }
    let total = 0
    if (plan) {
        total = planner.getPrice(account, plan, {period: true, upfront: true})
        page.price = planner.getPrice(account, plan, {period: true})
        page.yearlyPrice = planner.getPrice(account, plan)
        page.monthlyPrice = page.yearlyPrice / 12
        page.upfrontPrice = planner.getUpfront(plan)
        getRange()
    }
    page.total = total
}

function getRange() {
    let plan = page.plan
    let partial = ''
    if (plan.align) {
        let aligned = planner.alignDate(plan.start)
        if (aligned > plan.start) {
            //  Less than 5 days before the end of the month
            partial = `(next ${plan.period})`
        } else if (aligned < plan.start && plan.start.getTime() - aligned.getTime() > 86400 * 1000) {
            //  More than one day into the period
            partial = `(partial ${plan.period})`
        }
        page.partial = partial
    } else {
        page.partial = null
    }
    page.range = formatRange(plan.start, plan.end, partial)

    let current = plan.current
    if (current.start) {
        page.currentRange = formatRange(current.start, current.end)
    }
}

function formatRange(start, end, partial = '') {
    start = Dates.format(start, 'mediumDate')
    let displayEnd = planner.getDisplayEnd(end)
    end = Dates.format(displayEnd, 'mediumDate')
    return `${start} to ${end} ` + partial
}

function startProgress(msg, timeout = page.timeout) {
    Progress.start('dialog', msg, {bar: true, duration: timeout * 1000, cancel: cancelProgress})
}

async function cancelProgress() {
    if (
        confirm.value &&
        !(await confirm.value.ask(
            `<h2 class="mb-3">Your device cloud may be in a partial state.</h2>
            <p>You may re-save or delete the cloud to complete the operation.</p>`,
            {
                disagree: false,
                width: 700,
                title: 'Cloud Operation Cancelled',
            }
        ))
    ) {
        return
    }
    page.cloud.error = 'Cancelled operation'
    page.saving = false
}

async function getAsset(file, max, format = '') {
    if (file.size > max) {
        throw new Error(`Asset ${file.name} is bigger than max ${max / 1024 / 1024} MB`)
    }
    let reader = new FileReader()
    reader.readAsArrayBuffer(file)

    let data = await new Promise((resolve, reject) => {
        reader.onload = async () => {
            let data = reader.result
            if (format == 'json') {
                try {
                    let string = String.fromCharCode.apply(null, new Uint8Array(data))
                    data = Json5.parse(string)
                } catch (err) {
                    reject(new Error(`${file.name} contains invalid JSON`))
                }
            } else {
                data = arrayBufferToBase64(data)
            }
            resolve(data)
        }
    })
    return {name: file.name, size: file.size, data}
}

/*
function clearSchema() {
    if (page.cloud?.schema) {
        page.cloud.schema = null
    }
} */
</script>

<template>
    <div class="cloud-edit">
        <vu-sign
            v-if="page.cloud.id == page.evalCloud"
            class="ma-4 pb-4"
            name="cloud-edit-sign-warning"
            title="Eval Cloud"
            subtitle="Careful with this eval cloud"
            color="error"
            :required="true">
            <b>WARNING</b>
            : This is the Master Evaluation Cloud
        </vu-sign>
        <vu-form
            :data="page"
            :pre-save="preSave"
            :post-save="postSave"
            :options="{progress: false}"
            :save="save"
            :title="`${props.id ? 'Modify' : 'Add'} Cloud`"
            class="cloud-edit"
            help="/doc/ui/clouds/edit.html"
            ref="form">
            <vu-sign
                name="cloud-edit-sign-1"
                title="Device Clouds"
                subtitle="Create device clouds"
                color="accent">
                <p>
                    An device cloud is a hub for centrally managing a collection of devices from the
                    cloud. You can use the pre-supplied Eval cloud, create your own device cloud
                    hosted by EmbedThis, or you can create a dedicated device cloud in an existing
                    AWS account that you own. If you anticipate having more than 500 devices in
                    total, we recommend creating a dedicated device cloud.
                </p>

                <p>
                    There is a per-device fee based on the number of connecting devices each month
                    (min 1). Dedicated clouds have an additional per month flat fee. Billing is at
                    the end of the month. See
                    <a href="https://www.embedthis.com/builder/doc/plans/cloud/" target="_blank">
                        Ioto Cloud Pricing
                    </a>
                    for full pricing details, the
                    <a href="https://www.embedthis.com/pricing.html" target="_blank">
                        Ioto Cloud Pricing Calculator,
                    </a>
                    and the
                    <a href="https://www.embedthis.com/about/terms.html" target="_blank">
                        Licensing Terms.
                    </a>
                </p>
            </vu-sign>

            <v-alert
                v-if="page.cloud.error"
                icon="$error"
                class="error vcol-12 mb-5"
                :value="true"
                type="error"
                dismissible>
                <div>{{ page.cloud.error }}</div>
            </v-alert>

            <vu-input
                v-model="page.cloud.name"
                name="name"
                label="Cloud Name"
                :rules="page.rules.cloud"
                cols="12" />

            <vu-input
                v-model="page.hosted"
                @change="changeType"
                cols="6"
                density="compact"
                color="primary"
                name="hosted"
                type="switch"
                :disabled="page.cloud.id != null"
                :suffix="
                    page.cloud.type == 'hosted' ? 'Hosted by EmbedThis' : 'In your own AWS Account'
                " />

            <vu-input
                v-model="page.cloud.region"
                cols="6"
                label="Cloud Region"
                name="region"
                type="select"
                :disabled="page.cloud.id != null"
                :items="page.regions"
                :rules="page.rules.region" />

            <div>
                <p class="hint mt-2 mb-4 pb-0">
                    Choose to be billed each month based upon the number of devices connecting to
                    the cloud. Or select to enter an estimate of the number of connecting devices
                    and be billed yearly in advance.
                </p>
                <div class="vrow">
                    <vu-input
                        label="Billing Period"
                        v-model="page.monthly"
                        cols="6"
                        type="switch"
                        hide-details
                        class="mb-4"
                        :suffix="page.monthly ? 'Monthly Billing' : 'Yearly Billing'" />
                    <vu-input
                        v-if="!page.monthly"
                        type="switch"
                        density="compact"
                        name="Align Billing"
                        v-model="page.plan.align"
                        color="primary"
                        :suffix="page.plan.align ? 'Calendar Year' : 'Day'"
                        cols="6" />
                </div>
                <div class="vrow">
                    <vu-input
                        v-if="page.monthly"
                        label="Connecting Devices"
                        cols="6"
                        class="mt-2"
                        type="text"
                        :modelValue="page.plan.count"
                        :disabled="page.monthly ? true : false"
                        :rules="page.rules.positive" />
                    <vu-input
                        v-if="page.plan.type != 'Free' && !page.monthly"
                        v-model="page.plan.units"
                        cols="3"
                        label="Connecting Devices"
                        type="text"
                        class="mt-2"
                        :disabled="page.monthly ? true : false"
                        :rules="page.rules.positive" />
                    <vu-input
                        v-if="page.plan.type != 'Free' && !page.monthly"
                        label="Metered"
                        type="text"
                        cols="3"
                        class="ml-3 mt-2"
                        :disabled="true"
                        :modelValue="page.plan.count" />
                </div>
            </div>

            <div class="mb-4 vcol-12">
                <label class="options">Additional Options</label>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-title>Device Data Schema</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div class="vrow">
                                <vu-input
                                    v-model="page.upload.schema"
                                    label="Upload Schema"
                                    type="checkbox"
                                    cols="3" />
                                <vu-input
                                    v-if="!page.upload.schema && page.cloud.schema?.name"
                                    v-model="page.clear"
                                    :label="`Clear Schema (${page.cloud.schema.name})`"
                                    type="checkbox"
                                    hide-details="auto"
                                    variation="compact"
                                    cols="6" />
                            </div>
                            <v-file-input
                                v-if="page.upload.schema"
                                v-model="page.files.schema"
                                class="vcol-6"
                                label="Schema File..."
                                show-size
                                :clearable="true" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel>
                        <v-expansion-panel-title>Edge Tables</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <p class="hint">Transparent AWS DynamoDB database synchronization.</p>
                            <vu-input
                                v-model="page.cloud.sync"
                                label="Database Synchronization"
                                type="checkbox" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="page.cloud.id && page.cloud.type == 'dedicated'">
                        <v-expansion-panel-title>Device Keys IAM Role</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <p class="hint">
                                Use a custom IAM Role to limit privileges for devices accessing AWS.
                            </p>
                            <v-text-field
                                v-model="page.cloud.deviceRole"
                                label="Device IAM Role"></v-text-field>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="page.cloud.id && page.cloud.type == 'dedicated'">
                        <v-expansion-panel-title>IoT Policy</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <p class="hint">AWS IoT core policy to limit MQTT privileges.</p>
                            <v-text-field
                                v-model="page.cloud.iotPolicy"
                                label="IoT Policy"></v-text-field>
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="page.cloud.id && page.cloud.type == 'dedicated'">
                        <v-expansion-panel-title>IoT Device Shadows</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <p class="hint">Maintain IoT Device Shadow state.</p>
                            <vu-input
                                v-model="page.cloud.shadows"
                                label="AWS IoT Device Shadows"
                                type="checkbox" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel v-if="page.cloud.id">
                        <v-expansion-panel-title>
                            Activate Cloud {{ !page.cloud.enable ? '(Disabled)' : '' }}
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <vu-input
                                v-model="page.cloud.enable"
                                label="Enable Cloud"
                                type="checkbox" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>

                    <v-expansion-panel
                        v-if="page.cloud.api && page.cloud.id != page.evalCloud && page.cloud.api">
                        <v-expansion-panel-title>API Access</v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <p class="hint">
                                Use this endpoint for REST API requests to this device cloud.
                            </p>
                            <p class="token">
                                {{ page.cloud.api }}
                                <vu-clip class="float-right mr-1" :text="page.cloud.api" />
                            </p>
                            <p class="hint">
                                Get an authentication Token for admin access from the relevant
                                Device Manager.
                            </p>
                            <vu-input v-model="page.cloud.id" label="Cloud ID" :disabled="true" type="text" cols="12" />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>

            <div class="actions">
                <v-btn color="accent" type="submit" :loading="page.saving">Save</v-btn>
                <v-btn color="none" @click="emit('input')">Cancel</v-btn>
                <v-btn color="error" v-if="props.id && can('admin')" @click="removeCloud">
                    Delete
                </v-btn>
            </div>

            <vu-sign
                v-if="page.cloud.id != page.evalCloud"
                :required="true"
                class="summary vcol-12"
                color="accent"
                name="summary"
                title="Subscription Estimate">
                <v-row>
                    <v-col cols="12">
                        <v-table density="compact">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Period</th>
                                    <th>Quantity</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="page.plan.current.start">
                                    <td>Current</td>
                                    <td>{{ page.currentRange }}</td>
                                    <td>
                                        {{ page.plan.current.units }} device{{
                                            page.plan.current.units == 1 ? '' : 's'
                                        }}
                                    </td>
                                    <td>
                                        ${{
                                            money(
                                                page.plan.current.price - page.plan.current.upfront,
                                                0
                                            )
                                        }}
                                        paid
                                    </td>
                                </tr>
                                <tr v-if="page.upfrontPrice">
                                    <td>Upfront fee</td>
                                    <td>One time</td>
                                    <td>1</td>
                                    <td>${{ money(page.upfrontPrice, 0) }}</td>
                                </tr>
                                <tr>
                                    <td>Cloud Subscription</td>
                                    <td>{{ page.range }}</td>
                                    <td>
                                        {{ page.plan.units }} device{{
                                            page.plan.units == 1 ? '' : 's'
                                        }}
                                    </td>
                                    <td>${{ money(page.price, 0) }}</td>
                                </tr>
                                <tr>
                                    <td><b>Total</b></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <b>${{ money(page.total, 0) }}</b>
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-col>
                </v-row>
                <v-row class="mt-0">
                    <v-col>
                        <p>
                            <span v-if="page.plan.period == 'month' && page.partial">
                                Next month: ${{ money(page.monthlyPrice, 0) }},
                            </span>
                            <span v-if="page.plan.period == 'month' || page.partial">
                                Full year price: ${{ money(page.yearlyPrice, 0) }}
                            </span>
                        </p>
                    </v-col>
                </v-row>
            </vu-sign>
        </vu-form>

        <v-dialog v-model="page.ask" persistent width="700" content-class="iam-dialog">
            <vu-form :data="page" title="Connect Your AWS Account to Ioto" :save="startTemplate">
                <p>
                    Click
                    <b>OK</b>
                    to open the AWS console in a new browser tab in your desired AWS account to use
                    for Ioto. Then approve the AWS CloudFormation Template. This will create an AWS
                    IAM access role in your AWS account for use by Ioto.
                </p>
                <p>
                    Tick the checkbox and click "
                    <b>Create Stack</b>
                    " at the lower right hand side of the CloudFormation template. It will take up a
                    few minutes for AWS to complete the task.
                </p>
                <div>
                    <p>
                        <b>NOTE:</b>
                        if your browser is configured to block popups, click this link:
                    </p>
                    <p>
                        <a :href="page.template" :disabled="!page.template" target="_blank">
                            Open AWS Console.
                        </a>
                    </p>
                </div>
                <p>When complete, come back to this Embedthis Builder browser tab.</p>
                <div class="actions">
                    <v-btn
                        size="small"
                        color="accent"
                        :disabled="!page.template"
                        @click="startTemplate">
                        OK
                    </v-btn>
                    <v-btn size="small" color="none" @click="cancelTemplate">Cancel</v-btn>
                </div>
            </vu-form>
        </v-dialog>
        <vu-confirm ref="confirm" />
    </div>
</template>

<style lang="scss">
.cloud-edit {
    label.options {
        margin-top: 16px;
        padding-bottom: 8px;
        display: block;
        font-size: 1.125rem;
        color: rgb(var(--v-theme-text));
    }
    .v-alert.error {
        border: none;
        h2 {
            color: white;
        }
        a {
            color: white;
            font-weight: bold;
        }
    }
    .v-expansion-panel {
        .v-expansion-panel-title {
            color: rgb(var(--v-theme-text));
            font-weight: normal;
            font-size: 1.2rem;
        }
    }
    .v-btn {
        margin-left: 0;
    }
    .token {
        font-size: 1rem;
    }
    .actions {
        margin-top: 20px;
    }
    .summary {
        margin: 20px 0 30px 0;
        p {
            margin-bottom: 12px;
        }
        th,
        td {
            padding: 0 8px 0 8px !important;
        }
    }
    .expando {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .token {
        font-weight: bold;
    }
    .units {
        .v-field__input {
            text-align: right;
        }
    }
}
.iam-dialog {
    background-color: rgb(var(--v-theme-background));
    h1 {
        background-color: rgb(var(--v-theme-primary));
        margin: -20px -20px 20px -20px !important;
        padding: 26px 16px 16px 16px;
        color: white;
    }
    .actions {
        margin-bottom: 0px;
    }
    p {
        margin-bottom: 12px;
    }
}
.dialog-progress {
    z-index: 200;
    .headline {
        color: white;
    }
}
</style>
